<template>
  <div v-resize="onResize">
    <general-text
      :text-types="TEXT_TYPES.title"
      dictionary-key="general_all_games"
      class="j-text--white mt-lg-7"
    />
    <j-page-games-game-filter
      ref="allGamesGameFilter"
      class="mt-6"
      :game-type="GAMES_TYPES.all"
      :loading="loading"
      expand
      change-meta
      :categories="getAllGames.categories"
      :providers="getAllGames.providers"
      @input-event="infiniteScrollMode = 'manual'"
      @update:loading="loading = $event"
      @update:is-lazy-loading="isLazyLoading = $event"
    />
    <j-dynamic-content-infinite-scroll
      class="mt-4 mt-lg-6"
      :items="getAllGames.items"
      :items-promo="showPromoTiles ? getPromoTiles : []"
      :total="getAllGames.total"
      :mode="infiniteScrollMode"
      :no-items-text="$t('general_game_not_found')"
      :loading="loading"
      :lazy-loading="isLazyLoading"
      :remote-storage-host="getAllGamesRemoteStorageHost"
      public-img-store-dir-name="games"
      :load-content="loadGamesHandler"
      @update:mode="infiniteScrollMode = $event"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useGamesStore } from '~/stores/games';
import { useHomePageStore } from '~/stores/homepage';
import {
  PLATFORM,
  TEXT_TYPES,
  GAMES_TYPES,
  GAME_CATEGORIES,
  GAME_PROVIDERS,
} from '~/constants/general';
import type { Mode } from '~/types/components/General/DynamicContentInfiniteScroll';
import type {
  GameFilterInstance,
  FilterParams,
} from '~/types/pages/homepage';

definePageMeta({
  middleware: [
    'seo-crawler-detector',
    'games',
    'ensure-canonical-available',
  ],
});
const { t } = useI18n();
const route = useRoute();

usePageMeta(
  t(`games_provider_${(route.params.gameProvider as string).replaceAll('-', '_')}.page_title`),
  t(`games_provider_${(route.params.gameProvider as string).replaceAll('-', '_')}.page_description`),
);

const { $eventEmitter } = useNuxtApp();
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const currentPlatform = computed(() =>
  isMobileMode?.value
    ? PLATFORM.mobile
    : PLATFORM.desktop,
);
const previousPlatform = ref(currentPlatform.value);
const gamesStore = useGamesStore();
const {
  getAllGames,
  getAllGamesRemoteStorageHost,
  getIsSeoCrawlerDetected,
} = storeToRefs(gamesStore);
const homePageStore = useHomePageStore();
const { getPromoTiles } = storeToRefs(homePageStore);
const {
  fetchPromoTilesData,
  fetchPromoTilesDataForSSR,
} = homePageStore;
const loading = ref(false);
const infiniteScrollMode = ref<Mode>('manual');
const allGamesGameFilter = ref<GameFilterInstance>(null);
const loadGamesHandler = async () => {
  await allGamesGameFilter.value?.loadGames(true);
  $eventEmitter.on(
    'get-filter-params',
    (params: FilterParams) => (filterParams.value = params),
  );
};
const {
  setParamsToFetchGamesParams,
  loadGamesSSR,
} = useFetchGamesSSR(GAMES_TYPES.all);
const filterParams = ref<FilterParams | null>(null);
const showPromoTiles = computed(() => {
  const {
    gameCategory,
    gameProvider,
    ...rest
  } = filterParams.value || {
    gameCategory: (route.params.gameCategory as string).toUpperCase(),
    gameProvider: (route.params.gameProvider as string).toUpperCase(),
  };

  if (gameCategory !== GAME_CATEGORIES.all || gameProvider !== GAME_PROVIDERS.all) {
    return false;
  }

  const allowedFields = new Set(['gameCategory', 'gameProvider', 'offset', 'limit', 'platform']);

  return Object.keys(rest).every((key) => allowedFields.has(key));
});
const isLazyLoading = ref(false);
const onResize = () => {
  if (currentPlatform.value !== previousPlatform.value) { // Check for platform change
    previousPlatform.value = currentPlatform.value; // Update previous platform
    // Perform actions when platform changes
    fetchPromoTilesData(currentPlatform.value);
    allGamesGameFilter.value?.loadGames(false); // Reset and load all games for the new platform
  }
};

onMounted(() => {
  nextTick(async () => {
    $eventEmitter.on(
      'get-filter-params',
      (params: FilterParams) => (filterParams.value = params),
    );

    if (!getIsSeoCrawlerDetected.value) {
      await fetchPromoTilesData(currentPlatform.value);
    }
  });
});
onUnmounted(() => {
  $eventEmitter.off('get-filter-params');
});

/**
 * Fetches games & promo tiles data when component is server-side rendered.
 */
 await useAsyncData(
  'fetch-games-data',
  async () => {
    if (getIsSeoCrawlerDetected.value) {
      setParamsToFetchGamesParams();
      await fetchPromoTilesDataForSSR(PLATFORM.desktop);
      await loadGamesSSR();
    }

    return {};
  },
  { server: true },
);
</script>