<template>
  <div
    :class="[
      'j-page-loader',
      'd-flex',
      'justify-center',
      'align-center',
      'w-100',
      'h-100',
      { 'position-fixed top-0 right-0 bottom-0 left-0': fixed },
    ]"
  >
    <img
      class="j-page-loader__icon"
      :lazy-src="images['page_loader']"
      :src="images['page_loader']"
      alt="Preloader..."
    >
  </div>
</template>

<script setup lang="ts">
const images = useAssetsImages();
const props = defineProps({
  fixed: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
.j-page-loader {
  background: $j-color-bg-main;
  z-index: 99999;
}

.j-page-loader__icon {
  @include spinning(2s);
}
</style>
