<template>
  <v-sheet
    :color="scssVariables.jColorBgBlockNew"
    :class="[
      'j-content-card',
      'pb-3',
      isPromo ? 'j-content-card--br-16' : 'rounded-lg',
    ]"
  >
    <nuxt-link
      :to="useLocalePrefixForHref(getLink)"
      class="text-decoration-none"
    >
      <v-img
        :lazy-src="imgSrc"
        :src="imgSrc"
        alt="content-card"
        :class="[
          'j-content-card__img',
          { 'j-content-card__img--br-top-16': isPromo },
        ]"
      />
      <div class="mx-5 mt-4 mb-2">
        <general-text
          :text="title"
          class="j-text--white j-text--fs-16 font-weight-medium j-text--line-clamp-1"
        />
      </div>
      <div
        v-if="showDescription"
        class="mx-5"
      >
        <general-text
          :text="description"
          class="j-text--line-clamp-1"
        />
      </div>
    </nuxt-link>
  </v-sheet>
</template>

<script setup lang="ts">
const props = defineProps({
  imgSrc: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  showDescription: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    default: null,
  },
});
const route = useRoute();
const scssVariables = useScssVariables();
const isPromo = computed(() => route.path.includes('promo'));
const getLink = computed(() =>
  isPromo.value
    ? `/promo/${props.id}`
    : `/news/${props.id}`,
);
</script>

<style lang="scss" scoped>
.j-content-card {
  &.j-content-card--br-16 {
    border-radius: 16px;
  }
}
.j-content-card__img{
  &.j-content-card__img--br-top-16 {
    border-radius: 16px 16px 0 0;
  }
}
</style>
