<template>
  <nuxt-link :to="isMobileMode ? useLocalePrefixForHref(promoLink) : null">
    <div
      :class="[
        'j-content-promo-card',
        'rounded',
        'h-100',
        {
          'j-content-promo-card--no-img': failedImage,
        },
      ]"
    >
      <div :class="{ 'j-content-promo-card__logo': failedImage }">
        <v-img
          :lazy-src="imgSrc"
          :src="dynamicLinkImg"
          alt="content-promo-card"
          class="rounded"
          @error="setPromoPreloaderImage"
        />
      </div>
      <div class="j-content-promo-card__item-overlay pa-3 text-center d-flex flex-column rounded justify-center">
        <div v-if="promoName">
          <general-text
            :text="promoName"
            :class="[
              { 'j-text--fs-16': !isXsDisplay },
              'j-text--white',
              'font-weight-medium',
            ]"
          />
        </div>
        <div
          v-if="promoLink"
          class="j-content-promo-card__block j-content-promo-card__block--mh-100p"
        >
          <nuxt-link
            :to="useLocalePrefixForHref(promoLink)"
            class="j-link j-link--img j-link--d-contents"
          >
            <v-img
              :lazy-src="images['play_game']"
              :src="images['play_game']"
              alt="play-game"
              :max-width="iconWidth"
              max-height="100%"
              class="mx-auto"
            />
          </nuxt-link>
        </div>
      </div>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
const props = defineProps({
  imgSrc: {
    type: String,
    default: '',
  },
  promoName: {
    type: String,
    default: '',
  },
  promoLink: {
    type: String,
    default: '',
  },
});

const images = useAssetsImages();
const failedImage = ref(false);
const dynamicLinkImg = computed(() => failedImage.value
  ? images['page_loader']
  : props.imgSrc,
);
const setPromoPreloaderImage = () => failedImage.value = true;
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const iconWidth = computed(() => {
  return isXsDisplay?.value
    ? '40px'
    : '60px';
});
</script>

<style scoped lang="scss">
.j-content-promo-card {
  position: relative;
  background-color: $j-color-bg-block-light;
  &:hover {
    .j-content-promo-card__item-overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.j-content-promo-card--no-img {
  padding-top: 65.8192%;
}
.j-content-promo-card__item-overlay {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $j-color-content-item-overlay;
  opacity: 0;
  transition: .3s;
  z-index: 1;
}
.j-content-promo-card__block--mh-100p {
  max-height: 100%;
}
.j-content-promo-card__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  z-index: 0;
}
</style>
