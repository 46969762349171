<template>
  <div class="j-content-items-grid position-relative">
    <div
      v-if="lazyLoading"
      class="j-content-items-grid__lazy-loader"
    >
      <j-loader-indicator />
    </div>
    <template v-else>
      <div
        :style="gridStyle"
        class="j-content-items-grid__content"
      >
        <div
          v-for="(itemPromoData, index) in itemsPromo"
          :key="`${index}${(itemPromoData as PromoTileItem).buttonText}`"
          :class="[
            'j-content-items-grid__content-item',
            `j-content-items-grid__content-item--promo-position-${(itemPromoData as PromoTileItem).position}`,
          ]"
        >
          <general-content-promo-card
            :img-src="(itemPromoData as PromoTileItem).imageURL"
            :promo-name="(itemPromoData as PromoTileItem).buttonText"
            :promo-link="(itemPromoData as PromoTileItem).buttonURL"
          />
        </div>
        <div
          v-for="(itemData, index) in items"
          :key="`${index}${(itemData as GameItem).prettyName}`"
          class="j-content-items-grid__content-item"
        >
          <general-content-game-card
            v-if="showGameItems"
            :img-src="getGameImgSrc(itemData as GameItem)"
            :game-name="(itemData as GameItem).gameName"
            :display-policy="(itemData as GameItem).displayPolicy || (itemData as GameItem).displayPolicyName"
            :pretty-name="(itemData as GameItem).prettyName"
            :provider="(itemData as GameItem).gameProviderId"
            :label="(itemData as GameItem).label"
            :demo-mode-support="(itemData as GameItem).demoModeSupport"
            :allowed-mode="allowedMode"
          />
          <general-content-card
            v-else
            :id="(itemData as NewsItem).id"
            :img-src="(itemData as NewsItem).previewNewsImgUrl"
            :title="(itemData as NewsItem).title"
            :description="(itemData as NewsItem).shortText"
            :show-description="showDescription"
          />
        </div>
      </div>
      <div
        v-if="loading"
        class="j-content-items-grid__loader text-center position-absolute"
      >
        <v-progress-circular
          :size="50"
          :color="scssVariables.jColorPrimary"
          indeterminate
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { GameItem } from '~/types/general/game';
import type { NewsItem } from '~/types/general/news';
import type { PromoTileItem } from '~/types/pages/homepage';
import type { AllowedMode } from '~/types/components/General/ContentGameCard';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  itemsPromo: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  lazyLoading: {
    type: Boolean,
    default: false,
  },
  remoteStorageHost: {
    type: String,
    default: '',
  },
  columnCount: {
    type: Number,
    default: 0,
  },
  publicImgStoreDirName: {
    type: String,
    default: '',
  },
  columnGap: {
    type: Number,
    default: 0,
  },
  allowedMode: {
    type: String as () => AllowedMode,
    default: undefined as AllowedMode,
  },
  showDescription: {
    type: Boolean,
    default: false,
  },
});
const {
  items,
  remoteStorageHost,
  loading,
  columnCount,
  publicImgStoreDirName,
  columnGap,
} = toRefs(props);
const getGameImgSrc = (itemData: GameItem) => {
  let appStorageSrc;

  if (publicImgStoreDirName?.value) {
    appStorageSrc =
      publicImgStoreDirName?.value === 'games'
        ? `/images/${publicImgStoreDirName?.value}/${itemData.provider
          || itemData.externalProviderCode}/${itemData?.gameCode}.jpg`
        : `/images/${publicImgStoreDirName?.value}/${itemData?.gameCode}.jpg`;
  } else {
    appStorageSrc = images[itemData?.gameCode];
  }

  return itemData?.iconPath
    ? `${remoteStorageHost.value}${itemData?.iconPath}`
    : appStorageSrc;
};
const scssVariables = useScssVariables();
const images = useAssetsImages();
const gridStyle = computed(() => ({
  'grid-template-columns': `repeat(${columnCount.value}, 1fr)`,
  gap: `${columnGap.value}px`,
}));
const showGameItems = computed(() => publicImgStoreDirName?.value === 'games');
</script>

<style lang="scss" scoped>
.j-content-items-grid__content {
  display: grid;
}
.j-content-items-grid__loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.j-content-items-grid__lazy-loader {
  height: 300px;
  @media (min-width: 1280px) {
    height: 600px;
  }
}
.j-content-items-grid__content-item--promo-position-1 {
  grid-row: 1/3;
  grid-column: 1/3;

  @media(min-width: 600px) {
    grid-row: 1/3;
    grid-column: 3/5;
  }
  @media(min-width: 1280px) {
    grid-row: 1/3;
    grid-column: 2/4;
  }
}
.j-content-items-grid__content-item--promo-position-2 {
  grid-row: 6/8;
  grid-column: 1/3;

  @media(min-width: 600px) {
    grid-row: 5/7;
    grid-column: 1/3;
  }
  @media(min-width: 1280px) {
    grid-row: 4/6;
    grid-column: 4/6;
  }
}
.j-content-items-grid__content-item--promo-position-3 {
  grid-row: 11/13;
  grid-column: 1/3;

  @media(min-width: 600px) {
    grid-row: 9/11;
    grid-column: 3/5;
  }
  @media(min-width: 1280px) {
    grid-row: 7/9;
    grid-column: 1/3;
  }
}
.j-content-items-grid__content-item--promo-position-4 {
  grid-row: 16/18;
  grid-column: 1/3;

  @media(min-width: 600px) {
    grid-row: 13/15;
    grid-column: 1/3;
  }
  @media(min-width: 1280px) {
    grid-row: 10/12;
    grid-column: 5/7;
  }
}
.j-content-items-grid__content-item--promo-position-5 {
  grid-row: 21/23;
  grid-column: 1/3;

  @media(min-width: 600px) {
    grid-row: 17/19;
    grid-column: 3/5;
  }
  @media(min-width: 1280px) {
    grid-row: 13/15;
    grid-column: 2/4;
  }
}
</style>
