<template>
  <v-responsive
    :max-width="componentProps.maxWidth"
    :class="[
      'j-checkbox',
      {
        'j-checkbox--no-ripple': !componentProps.ripple,
      },
    ]"
  >
    <v-checkbox
      hide-details="auto"
      :label="componentProps.label"
      :model-value="modelValue"
      :type="componentProps.type"
      :error-messages="componentProps.errorMessages"
      :ripple="componentProps.ripple"
      @update:model-value="updateModelValue"
    >
      <template
        v-if="!componentProps.label"
        #label
      >
        <slot />
      </template>
    </v-checkbox>
  </v-responsive>
</template>

<script setup lang="ts">
import type { Density } from '~/types/components/General/TextField';

const componentProps = defineProps({
  label: {
    type: String,
    default: '',
  },
  density: {
    type: String as () => Density,
    default: 'default' as Density,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'string',
  },
  ripple: {
    type: Boolean,
    default: true,
  },
  errorMessages: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: [String, Number],
    default: '100%',
  },
});
const emit = defineEmits([
  'update:modelValue',
]);

const updateModelValue = (modelValue: boolean | null) => emit('update:modelValue', modelValue);
</script>

<style scoped lang="scss">
.j-checkbox{
  :deep(.v-input) {
    .v-label {
      font-size: $j-form-label-font-size;
      width: auto;
      opacity: 1;
    }
    .v-selection-control {
      min-height: initial;
      opacity: 1;
      .v-icon {
        &::before {
          content: url('/assets/images/general/icons/checkbox-false.svg')
        }
      }
      &--dirty {
        .v-icon {
          &::before {
            content: url('/assets/images/general/icons/checkbox-true.svg');
          }
        }
      }
    }
    .v-input__details {
      padding: 12px 0 0;
    }
  }
  :deep(.v-input--error:not(.v-input--disabled)) {
    .v-field {
      border-color: $j-color-error
    }
    .v-field__input {
      color: $j-color-error;
    }
    .v-input__details {
      .v-messages {
        color: $j-color-error;
        .v-messages__message {
          line-height: 16px;
        }
      }
    }
  }
  &--no-ripple:deep(.v-input) {
    .v-selection-control {
      align-items: flex-start;
      &__input {
        width: initial;
        height: initial;
        &::before {
          content: none;
        }
      }
      &__wrapper {
        width: initial;
        height: initial;
        margin-right: 8px;
      }
    }

  }
}
</style>
