<template>
  <div
    :class="[
      'j-content-game-card',
      'rounded',
      'h-100',
      {
        'j-content-game-card--no-img': failedImage && !smallCard,
        'j-content-game-card--small': smallCard,
      },
    ]"
  >
    <div class="j-content-game-card__label-wrapper">
      <div
        v-if="label"
        :class="[
          'j-content-game-card__label',
          'mt-2',
          'ml-2',
          'px-6',
          { 'j-content-game-card__label--primary': label === 'tournament' },
        ]"
      >
        <general-text
          :text="label"
          :class="[
            'text-uppercase',
            'j-text--white',
            'font-weight-bold',
            'font-italic',
            { 'j-text--fs-12': isSmDisplay },
          ]"
        />
      </div>
    </div>
    <div :class="{ 'j-content-game-card__logo': failedImage }">
      <v-img
        :lazy-src="dynamicLinkImg"
        :src="dynamicLinkImg"
        alt="content-game-card"
        class="rounded"
        @error="setGamePreloaderImage"
      />
    </div>
    <div
      :class="[
        'j-content-game-card__item-overlay',
        'pa-3',
        'text-center',
        'd-flex',
        'flex-column',
        'rounded',
        'justify-center',
        {
          'j-content-game-card__item-overlay--locked': isLockedGame,
          'justify-space-between': (!allowedMode && demoModeSupport) || isLockedGame,
          'px-0': isXsDisplay && isLockedGame,
        },
      ]"
    >
      <template v-if="isLockedGame">
        <div class="j-content-game-card__block j-content-game-card__block--mh-100p">
          <v-img
            :lazy-src="images['locked-game']"
            :src="images['locked-game']"
            alt="locked-game"
            :max-width="iconLockedWidth"
            max-height="100%"
            class="mx-auto"
          />
        </div>
        <general-text
          v-if="!smallCard"
          dictionary-key="general_game_is_temporary_restricted"
          :class="['j-text--white mt-2', { 'j-text--fs-12': isXsDisplay }]"
        />
      </template>
      <template v-else>
        <div v-if="gameName">
          <general-text
            :text="gameName"
            :class="[
              { 'j-text--fs-16': !isXsDisplay },
              { 'j-text--fs-12': isSmDisplay },
              'j-text--white',
              'font-weight-medium',
              'j-text--line-clamp-2',
            ]"
          />
        </div>
        <div class="j-content-game-card__block j-content-game-card__block--mh-100p">
          <nuxt-link
            :to="useLocalePrefixForHref(dynamicLinkGame)"
            class="j-link j-link--img j-link--d-contents"
            @click="gameCardSelectHandler()"
          >
            <v-img
              :lazy-src="images['play_game']"
              :src="images['play_game']"
              alt="play-game"
              :max-width="iconWidth"
              max-height="100%"
              class="mx-auto"
            />
          </nuxt-link>
        </div>
        <div v-if="!allowedMode && demoModeSupport">
          <nuxt-link
            :to="useLocalePrefixForHref(linkGameDemo)"
            class="j-link "
          >
            <general-text
              :text="$t('general_demo')"
              class="j-text--white font-weight-medium j-text--d-contents"
            />
          </nuxt-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  GAME_CARD_DISPLAY_POLICY,
  REQUIRED_AUTH_PAGES_WITH_CHILDREN,
} from '~/constants/general';
import type { AllowedMode } from '~/types/components/General/ContentGameCard';

const props = defineProps({
  imgSrc: {
    type: String,
    default: '',
  },
  gameName: {
    type: String,
    default: '',
  },
  displayPolicy: {
    type: String,
    default: '',
  },
  prettyName: {
    type: String,
    default: '',
  },
  provider: {
    type: Number,
    default: null,
  },
  smallCard: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  demoModeSupport: {
    type: Boolean,
    default: false,
  },
  allowedMode: {
    type: String as () => AllowedMode,
    default: undefined as AllowedMode,
  },
  gameCardSelectHandler: {
    type: Function,
    default: () => ({}),
  },
});

const images = useAssetsImages();
const failedImage = ref(false);
const dynamicLinkImg = computed(() => failedImage.value
  ? images['page_loader']
  : props.imgSrc,
);
const isLockedGame = computed(() => props.displayPolicy === GAME_CARD_DISPLAY_POLICY.locked);
const setGamePreloaderImage = () => failedImage.value = true;
const isSmDisplay = inject<Ref<boolean>>('isSmDisplay');
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const iconWidth = computed(() => {
  switch (true) {
    case props.smallCard:
      return '32px';
    case isSmDisplay?.value:
      return '25px';
    case isXsDisplay?.value && (import.meta.client && window.innerWidth < 400):
      return '25px';
    case isXsDisplay?.value:
      return '40px';
    default:
      return '60px';
  }
});
const iconLockedWidth = computed(() => {
  return props.smallCard
    ? '32px'
    : isXsDisplay?.value
      ? '32px'
      : '48px';
});
const dynamicLinkGame = computed(() => {
  const gameModes = Object.values(REQUIRED_AUTH_PAGES_WITH_CHILDREN.game);

  return gameModes.includes(String(props.allowedMode)) ?
    `/game/${props.prettyName}/${props.allowedMode}`
    : `/game/${props.prettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.real}`;
});
const linkGameDemo =
  computed(() => `/game/${props.prettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.fun}`);
</script>

<style scoped lang="scss">
.j-content-game-card {
  position: relative;
  background-color: $j-color-bg-block-light;
  &:hover {
    .j-content-game-card__item-overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.j-content-game-card--small {
  min-width: 82px;
  min-height: 54px;
}
.j-content-game-card--no-img {
  padding-top: 65.8192%;
}
.j-content-game-card__item-overlay {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $j-color-content-item-overlay;
  opacity: 0;
  transition: .3s;
  z-index: 1;
}
.j-content-game-card__item-overlay--locked {
  background: $j-gradient-game-card-locked;
  opacity: 1;
  pointer-events: auto;
}
.j-content-game-card__block--mh-100p {
  max-height: 100%;
}
.j-content-game-card__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  z-index: 0;
}
.j-content-game-card__label-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.j-content-game-card__label {
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
  background-size: cover;
  background-position: center;
  background-image: url('/assets/images/games/game-label-gradient.svg');
  &::after {
    content: '';
    position: absolute;
    width: 73%;
    height: 25px;
    left: 12px;
    bottom: 0;
    box-shadow: 0 15px 29px 0 rgba(0, 0, 0, 0.75);
    z-index: -1;
  }
}
.j-content-game-card__label--primary {
  background-image: url('/assets/images/games/game-label-primary.svg');
}
</style>
